<template>
  <div id="all_statistic" class="charts"></div>
</template>

<script>
let echarts = require("echarts");
export default {
  data() {
    return {};
  },
  mounted() {
    this.allStatisticEchart = echarts.init(
      document.getElementById("all_statistic")
    );
    // this.setAllStatisticEchart();
  },
  created() {
  },
  props: {
    barData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    setAllStatisticEchart() {
      let data = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          bottom: 0
        },
        grid: {
          left: "20",
          right: "20",
          bottom: "10",
          top: "16",
          containLabel: true
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
							formatter: '{d}%',
							position: 'inside',
							color: '#ffffff',
            },
            emphasis: {
              label: {
                show: true,
              }
            },

            labelLine: {
            },
            data: [
              {
                value: this.barData.onTime,
                name: this.$publicConfig.attendanceType[0],
                itemStyle: {
                  color: this.$publicConfig.attendanceTypeColor[0]
                }
              },
              {
                value: this.barData.late,
                name: this.$publicConfig.attendanceType[1],
                itemStyle: {
                  color: this.$publicConfig.attendanceTypeColor[1]
                }
              },
              {
                value: this.barData.leave,
                name: this.$publicConfig.attendanceType[2],
                itemStyle: {
                  color: this.$publicConfig.attendanceTypeColor[2]
                }
              },
              {
                value: this.barData.notArrive,
                name: this.$publicConfig.attendanceType[3],
                itemStyle: {
                  color: this.$publicConfig.attendanceTypeColor[3]
                }
              }
            ]
          }
        ]
      };
      this.allStatisticEchart.setOption(data);
    }
  },
  watch: {
    'barData.studentAmount'() {
      this.setAllStatisticEchart()
    }
  }
};
</script>

<style lang="scss" scoped>
.charts {
  width: 100%;
  height: 391px;
}
</style>